import React from 'react'
import AgriStyling from './Agriculture'
import InspectionStyling from './Inspection'
import MedicalStyling from './Medical'


const Services = () => {
  return (
    <>
    <AgriStyling />
    <InspectionStyling />
    {/* <MedicalStyling /> */}
   
    </>
  )
}

export default Services;