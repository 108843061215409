import React from "react";
 
import {HeroContainer,HeroBg,VideoBg,HeroContent,Img,
Heroh1,HeroP} from './HeroElements.js';
import landing from '../images/landing.jpeg'


const HeroStyling=()=>{
    
    return(
        <HeroContainer>
            <HeroBg>
                <VideoBg >
                   <Img src={landing} alt=" landing page" />
                </VideoBg> 
            </HeroBg>
            <HeroContent>
                <Heroh1>
                   Build an Application Powered by Machine Learning
                </Heroh1>
                <HeroP>
                    We develop efficient customizable machine solutions for your buisness problems.
                </HeroP>
               
            </HeroContent>
        </HeroContainer>


        
    );
    

};
export default HeroStyling;